import * as React from "react";
import Navbar from "../components/navbar";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import GoBackButton from "../components/goBackButton";

const Product = () => {
  const [mobile, setMobile] = React.useState(false);
  React.useEffect(() => {
    const { innerWidth: width } = window;
    width > 750 ? setMobile(false) : setMobile(true);
  }, []);

  const elementRef = React.useRef();
  const [elHeight, setElHeight] = React.useState(315);
  React.useEffect(() => {
    setElHeight(elementRef.current?.clientHeight);
  }, [elementRef]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>Merth.dev CRM</title>
      </Helmet>
      <Navbar />
      <main className=" bg-primary">
        <h1 className="text-center">Customer Relationship Management</h1>
        <div className="row justify-content-center">
          <div className="row py-4 h-100 ">
            <div
              className={`col-md-6 text-center  h-100 align-self-center ${
                mobile ? "g-0 pb-1" : "px-4"
              }`}
            >
              <iframe
                width="100%"
                height={`${!mobile ? elHeight + "px" : ""}`}
                src="https://www.youtube.com/embed/_keBQUov5tM"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div ref={elementRef} className="col-md-6 bg-paragraph">
              <p
                className={` ${mobile ? "p-2 fs-6" : "p-4 justify-text fs-5"}`}
              >
                This web application is a CRM (Customer
                Relationship Management) tool designed to manage customer
                contact information and company details. It provides valuable
                statistics on new customer acquisitions, spoken languages, and
                countries. Additionally, it offers the ability to send messages
                using the Twilio API. The application caters to two distinct
                account types: sellers and managers. Sellers have access to
                their own customer data for browsing and editing, ensuring data
                privacy and management efficiency.
                <br />
                <i>
                  <small>
                    Used technologies : ReactJs, Python/FastApi, SQLite and
                    Twilio API.
                  </small>
                </i>
              </p>
            </div>
          </div>
        </div>
        <div className={`row ${mobile ? "" : "px-3"}`}>
          <StaticImage
            src={`./../images/crm.jpg`}
            alt="service"
            placeholder="blurred"
          />
        </div>
        <GoBackButton />
      </main>
    </>
  );
};

export default Product;
